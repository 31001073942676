@import '~bootstrap/scss/functions';
@import '../functions';
@import '../variables';

@font-face {
  font-family: '#{$nbw-icons-font-family}';
  src: url('#{$nbw-icons-font-path}/#{$nbw-icons-font-family}.ttf?k8wnpv') format('truetype'),
    url('#{$nbw-icons-font-path}/#{$nbw-icons-font-family}.woff?k8wnpv') format('woff'),
    url('#{$nbw-icons-font-path}/#{$nbw-icons-font-family}.svg?k8wnpv##{$nbw-icons-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
